const FRAGMENT_KIND = Object.freeze({
    primary: 'primary',
    essential: 'essential',
    regular: 'regular',
});

const TRANSIENT_PARAMS = new Set([
  'videoId', 'pname', 'smid', 'showStartCamMessageOnRedirect'
]);

const SESSION_PARAMS = new Set([
  'vidfrm', 'imgfrm', 'up', 'display', 'flow', 'ov', 'useModalForm', 'surveyId', 'thumbfrm'
]);

const EXCLUDED_QUERY_PARAM_COOKIE = Object.freeze({
    '_ga': '_ga',
    '_gl': '_gl',
    'p': 'p',
    'r': 'r',
    'nosnd': 'nosnd',
    'ov_img': 'ov_img',
    'ov_text': 'ov_text',
    'target': 'target',
    'vidhp': 'vidhp',
    'aff_click_id': 'aff_click_id',
    'aff_id': 'aff_id',
    'aff_sub2': 'aff_sub2',
    'aff_sub3': 'aff_sub3',
    'aff_sub4': 'aff_sub4',
    'aff_sub5': 'aff_sub5',
    'aff_unique1': 'aff_unique1',
    'aff_unique2': 'aff_unique2',
    'aff_unique3': 'aff_unique3',
    'aff_unique4': 'aff_unique4',
    'aff_unique5': 'aff_unique5',
    'bo': 'bo',
    'campaign_id': 'campaign_id',
    'grd': 'grd',
    'offer_id': 'offer_id',
    'outlinks_set_id': 'outlinks_set_id',
    'promocode': 'promocode',
    'promocodeInfo': 'promocodeInfo',
    'sakey': 'sakey',
    'source': 'source',
    'transaction_id': 'transaction_id',
    'url_id': 'url_id',
    'xid': 'xid',
});

const WARNING_PATH = new Set(['/warning', '/avertissement', '/advertencia', '/avvertimento', '/aviso', '/warnung']);

module.exports = {
    FRAGMENT_KIND,
    EXCLUDED_QUERY_PARAM_COOKIE,
    SESSION_PARAMS,
    TRANSIENT_PARAMS,
    WARNING_PATH,
};
